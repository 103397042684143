.forside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-image: linear-gradient(#d9cdff, #cdeee5);
  color: #2434e1;
}

.forside-topp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  margin-bottom: 3rem;
}

.auksjon_banner {
  width: 25%;
}

.overskrift {
  align-self: flex-start;
  margin-left: 2rem;
  color: #c01b70;

}

.overskrift2 {
  align-self: flex-start;
  margin-left: 3rem;
  margin-top: 1rem;

}

.overskrift3 {
  align-self: flex-start;
  margin-left: 2rem;
  margin-top: 3rem;
  transition-duration: 1300ms;

}

.overskrift4 {
  align-self: flex-start;
  margin-left: 2rem;
  font-style: italic;
  margin-top: 3rem;
  transition-duration: 1200ms;

}

.auksjon_bilde-container {
  width:50rem;
  margin: 2rem 1rem 2rem 1rem;
}

.auksjon_bilde {
  width:100%;
  margin-bottom: 1rem;
}

.strek {
  margin-top: 1rem;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(#d9cdff, #c01b70);
}

@media only screen and (max-width: 600px) {
  .auksjon_bilde-container {
    width: 100%;
    margin: 2rem 1rem 2rem 1rem;
  }

  .auksjon_banner {
    width: 100%;
  }

  .forside-toppr {
    width: 100%;
    margin: 2rem 1rem 2rem 1rem;
  }

  .forside {
    padding: 1rem;
  }
}

